import { useSelector } from 'react-redux';

export const useCurrentOrderStudentId = () => {
  const selectedStudentId = useSelector(
    (state) => state.additionalData.selectedStudent?.id,
  );

  const order = useSelector((state) => state.v3Order.orders[selectedStudentId]);

  if (order && selectedStudentId) {
    return selectedStudentId;
  }

  return null;
};
